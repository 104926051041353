import React from "react"
import styled from "styled-components"

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 85%,
    rgba(0, 0, 0, 0.5) 100%
  );
  z-index: 1;

  @media (max-width: 768px) {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 12.5%,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
`

const Calque = () => <Gradient />

export default Calque
