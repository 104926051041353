import styled from "styled-components"

const Grid = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: min-content auto min-content;
  align-items: center;
  justify-items: center;
  padding: 25px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 15px 25px;
  }

  @media (max-width: 320px) {
    padding: 15px 17px;
  }
`

export default Grid
