import React, { Component } from "react"

function detectDevice(WrappedComponent) {
  return class extends Component {
    state = {
      desktop: true,
      tablet: false,
      mobile: false,
    }

    componentDidMount() {
      this.detectDevice()
      window.addEventListener("resize", this.detectDevice)
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.detectDevice)
    }

    detectDevice = () => {
      const width = window.innerWidth

      if (width > 1021) {
        this.setState({ desktop: true, tablet: false, mobile: false })
      }
      if (width <= 1021) {
        this.setState({ desktop: false, tablet: true, mobile: false })
      }
      if (width <= 750) {
        this.setState({ desktop: false, tablet: false, mobile: true })
      }
    }

    render() {
      return <WrappedComponent device={this.state} {...this.props} />
    }
  }
}

export default detectDevice
