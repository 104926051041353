/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Meta from "./Meta"
import "./layout.css"

const Main = styled.main`
  position: relative;
  border: 25px solid #cd1719;

  @media (max-width: 768px) {
    border-width: 15px;
  }
`

const Layout = ({ children }) => (
  <>
    <Meta />
    <Main>{children}</Main>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
