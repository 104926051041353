import React from "react"
import useImages from "../hooks/use-images-hook"
import Img from "gatsby-image"
import detectDevice from "../hoc/detect-device"
import Layout from "../components/Layout"
import Grid from "../components/Grid"
import Calque from "../components/Calque"
import AnimatedGif from "../components/AnimatedGif"
import Footer from "../components/Footer"

const IndexPage = ({ device }) => {
  const images = useImages()

  const sources = [
    images.mobile.childImageSharp.fluid,
    {
      ...images.desktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <Grid>
        <Img
          alt="Dallas Lisboa"
          fluid={images.logo.childImageSharp.fluid}
          style={{
            width: device.mobile ? "75px" : "90px",
          }}
        />
        <AnimatedGif />
        <Footer />
      </Grid>
      <Calque />
      <Img
        fluid={sources}
        objectFit="cover"
        objectPosition="50% 50%"
        style={{
          height: "100%",
          width: "100%",
        }}
        imgStyle={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      />
    </Layout>
  )
}

export default detectDevice(IndexPage)
