import React from "react"
import instagram from "../images/svg/IG.svg"
import facebook from "../images/svg/FB.svg"
import mail from "../images/svg/Mail.svg"
import styled from "styled-components"

const Container = styled.div`
  text-align: center;

  .blurb {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  footer {
    display: grid;
    margin-top: 35px;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 768px) {
      align-items: end;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
      font-size: 11px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      margin-bottom: 5px;
    }

    a {
      padding: 0 5px;
    }
  }

  .instagram {
    width: 17px;
  }
  .facebook {
    width: 8px;
  }
  .mail {
    width: 17px;
  }

  .address {
    @media (max-width: 768px) {
      grid-column: 1 / 2;
      grid-row: 2 / 2;
    }
  }

  .hours {
    @media (max-width: 768px) {
      grid-column: 2 / 2;
      grid-row: 2 / 2;
    }
  }

  .contact {
    @media (max-width: 768px) {
      margin-bottom: 20px;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row: 1 / 2;
    }
  }
`

const Footer = () => {
  return (
    <Container>
      <div className="blurb">
        100% Grass-fed Portuguese beef - Fresh products sourced locally -
        Homemade fries
      </div>
      <footer>
        <ul className="address">
          <li>Tv. Dos Remolares 41</li>
          <li>1200-372 Lisbon</li>
        </ul>

        <ul className="hours">
          <li>Monday to Sunday </li>
          <li>12h00 - 00h00 </li>
        </ul>

        <ul className="contact">
          <li className="social">
            <a
              href="https://www.instagram.com/dallas_lisboa/?hl=en"
              target="__blank"
            >
              <img
                className="instagram"
                src={instagram}
                alt="Visit Dallas on instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/dallasburgerjoint/"
              target="__blank"
            >
              <img
                className="facebook"
                src={facebook}
                alt="Visit Dallas on facebook"
              />
            </a>
            <a href="mailto:hello@dallaslisboa.com" target="__blank">
              <img className="mail" src={mail} alt="Contact Dallas by email" />
            </a>
          </li>
          <li>No reservations</li>
          <li>
            <a href="mailto:hello@dallaslisboa.com">hello@dallaslisboa.com</a>
          </li>
        </ul>
      </footer>
    </Container>
  )
}

export default Footer
