import { useStaticQuery, graphql } from "gatsby"

const UseImages = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: { eq: "Dallas_DIY_Kit_Landing_Page_Desktop.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(
        relativePath: { eq: "Dallas_DIY_Kit_Landing_Page_Mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "Dallas_Lisboa_Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}

export default UseImages
