import React from "react"
import animatedLogo from "../images/Dallas_logo_animation.gif"
import styled from "styled-components"

const Container = styled.div``

const AnimatedGif = () => (
  <Container>
    <a href="https://dallaslisboa.myshopify.com">
      <img src={animatedLogo} alt="Order the Dallas burger kit here" />
    </a>
  </Container>
)

export default AnimatedGif
